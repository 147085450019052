.flex_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.body1_2 {
  font: bold 12px "Arial";
  line-height: 15px;
}
.body2_2 {
  font: 11px "Arial";
  line-height: 14px;
}
.ft0 {
  font: 15px "Times New Roman";
  line-height: 16px;
}
.ft1 {
  font: bold 11px "Arial";
  line-height: 14px;
}
.ft2 {
  font: bold 13px "Arial";
  line-height: 16px;
}
.ft3 {
  font: bold 8px "Arial";
  line-height: 10px;
}
p {
  margin: 0px;
}
table,
.table {
  width: 100%;
  overflow: hidden;
  border: 3px solid #000;
  border-radius: 10px;
  border-spacing: 0px;
  background-color: #f2f2f2;
}
tr {
  border-spacing: 0px;
}
th,
.th {
  padding: 4px 0px;
  background-color: #cbcbcb;
  border-bottom: 2px solid #000;
}
td {
  padding: 0px;
}
.signature_wrapper {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 3px solid #000;
  margin-top: 10px;
  background-color: #f2f2f2;
  justify-content: space-between;
}
.divider_vertical,
.divider_horizontal {
  background-color: #000;
}
.divider_horizontal {
  width: 100%;
  height: 1px;
}
.divider_vertical {
  width: 1px;
  height: 100%;
  z-index: 1;
}
.cell,
.cell_number {
  text-align: center;
  background-color: #fff;
}
.cell {
  width: 18px;
  height: 20px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
}
.cell_number {
  height: 16px;
  padding: 2px;
  font: 13px "Arial";
  line-height: 16px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}
.field {
  height: 20px;
  background-color: #fff;
  font: 16px "Arial";
  line-height: 19px;
  text-align: center;
}
.ft19 {
  font: 8px "Arial";
  line-height: 10px;
}
.ft20 {
  font: 13px "Arial";
  line-height: 16px;
}
.ft64 {
  font: bold 8px "Arial";
  line-height: 10px;
}
.arrow_down_icon {
  width: 14px;
  height: 30px;
  position: absolute;
  right: -3px;
  bottom: -30px;
  font-size: 48px;
  font-weight: 800;
  text-align: center;
}
.field_secondary {
  width: 48%;
  border: 1px solid #000;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  border-top: none;
  border-right: none;
}
