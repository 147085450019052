* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter";
}

html,
body {
  background-color: #f2f2f2;
}

::placeholder {
  font-family: "Inter";
  font-size: 15px;
  color: #9298aa;
}

select {
  position: relative;
  /* for Firefox */
  -moz-appearance: none;
  /* for Safari, Chrome, Opera */
  -webkit-appearance: none;
  outline: none;
}
select::-ms-expand {
  display: none;
}
select img {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 17px;
  right: 16px;
}
select img.expand {
  transform: translateY(180deg);
}

section {
  padding: 20px 16px;
  margin-bottom: 24px;
  background-color: #f2f2f2;
}

iframe {
  width: 200%;
  height: 200vh;
  border: none;
  -moz-transform: scale(0.5, 0.5);
  -webkit-transform: scale(0.5, 0.5);
  -o-transform: scale(0.5, 0.5);
  -ms-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  -moz-transform-origin: top left;
  -webkit-transform-origin: top left;
  -o-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}

.iframe_wrapper {
  overflow: auto;
  height: 100vh;
}

ul {
  list-style: none;
}
li {
  position: relative;
  margin: 16px 0px;
}
li::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #028a90;
  position: absolute;
  top: 7px;
  left: -17px;
}

.App {
  width: 100%;
  height: 100vh;
  max-width: 600px;
  position: relative;
  margin: 0 auto;
  background-color: #f2f2f2;
}

.Main {
  overflow-x: hidden;
  padding-bottom: 104px;
}

.flex_box,
.flex_box_vertical {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.flex_box_vertical {
  flex-direction: column;
}
.paper {
  min-height: 48px;
  padding: 11px 14px;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0px 3.99185px 9.97962px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.borderer_cover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  border-radius: 8px;
}
.h5 {
  font-weight: 600;
  font-size: 20px;
  color: #2c3550;
}
.h6 {
  font-weight: 700;
  font-size: 18px;
  color: #000000;
}
.body1 {
  font-size: 17px;
  font-weight: 400;
  color: #757575;
}
.body2 {
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  line-height: 150%;
}
.caption {
  font-weight: 600;
  font-size: 12px;
  color: #444444;
  line-height: 130%;
}
.button_active,
.button_inactive,
.button_inactive_secondary {
  font-weight: bold;
  font-size: 14px;
  max-width: 160px;
  width: 100%;
  height: 40px;
  padding: 11px 0px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
.button_active {
  color: #ffffff;
  background-color: #2cb061;
}
.button_inactive {
  color: #788090;
  background-color: #ffffff;
}
.button_inactive_secondary {
  cursor: default;
  color: #ffffff;
  background-color: #cccccc;
}
.submit_button {
  min-width: 280px;
  height: 56px;
  padding: 16px 0px;
  font-weight: 600;
  font-size: 18px;
}
.plus_button,
.delete_button {
  width: auto;
  padding: 3px 0px;
  padding-right: 10px;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  color: #0271bf;
  border: none;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 14px;
}
.delete_button {
  color: #ff3b30;
  margin-top: 0;
  padding-left: 10px;
}
.plus_button:hover,
.delete_button:hover {
  background-color: rgba(33, 150, 243, 0.08);
}
input[type="checkbox"] {
  width: 18px;
  height: 18px;
}
input[type="checkbox"]:checked {
  background: #2cb061;
  border: none;
  outline: none;
}
.input {
  width: 100%;
  padding: 17px 16px;
  font-size: 16px;
  color: #2c3550;
  background: #ffffff;
  border: 1px solid #e9f0ff;
  border-radius: 8px;
  outline: none !important;
}
.input[data-error="true"] {
  border: 1px solid red;
}
.input[data-error="true"] {
  border: 1px solid red;
}
.input:focus {
  border: #0271bf solid 0.5px;
}
.step {
  background-color: #ffffff;
}
.step_animated_left {
  animation: slideLeft 0.3s ease-out;
}
@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0%);
  }
}
.step_animated_right {
  animation: slideRight 0.3s ease-out;
}
@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}
.question {
  margin-bottom: 16px;
}
.label {
  margin-bottom: 5px;
}
.collapse {
  width: 100%;
  overflow: hidden;
  transition: all 0.2s ease-out;
  transform-origin: top left;
}
.collapse[data-expanded="true"] {
  max-height: 0px;
  transform: scaleY(0);
}
.collapse[data-expanded="false"] {
  max-height: 10000px;
  transform: scaleY(1);
}
.modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 50%;
  background-color: #f2f2f2;
  z-index: 100000;
}
.check_icon {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading_container {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.loading_indicator {
  width: 44px;
  height: 44px;
  display: inline-block;
  color: #028a90;
  animation: 1.4s linear 0s infinite normal none running animation_loading_icon;
}
.svg_circle {
  stroke: currentColor;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0;
  -webkit-animation: animation_circle 1.4s ease-in-out infinite;
  animation: animation_circle 1.4s ease-in-out infinite;
}

@keyframes animation_loading_icon {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animation_circle {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}

.button_p_absolute {
  width: 100%;
  position: absolute;
  bottom: 0px;
}
.error_message {
  color: red;
}
.fixedButton {
  width: 100%;
  max-width: 600px;
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  padding: 24px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.1);
  z-index: 11000;
}
.fixedButton button {
  text-transform: uppercase;
}
.list_wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.list_wrapper::-webkit-scrollbar {
  display: none;
}
.list_item {
  padding: 8px 16px;
  cursor: pointer;
}
.list_item:hover {
  background-color: #fff;
  box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.1);
}
.select_container {
  width: 100%;
  height: 56px;
  position: relative;
  cursor: pointer;
  padding-right: 40px;
}
.select_container .value {
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select_container img {
  position: absolute;
  top: 17px;
  right: 16px;
  z-index: 1;
}
.select_container img[data-open="true"] {
  transform: rotateX(180deg);
}
.select_container img[data-open="false"] {
  transform: rotateX(0deg);
}
.select_wrapper {
  width: 100%;
  position: absolute;
  top: 56px;
  left: 0px;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.12);
}
.select_wrapper p {
  padding: 10px;
  cursor: pointer;
}
.select_wrapper p:hover {
  background-color: #f2f2f2;
}
.checkbox {
  position: relative;
  background-color: #2cb061;
}
.checkbox::after {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  top: -3px;
  left: -3px;
  opacity: 0;
  background-image: url(./assets/icons/bxs-check-square.svg);
}
.checkbox:checked::after {
  opacity: 1;
}
